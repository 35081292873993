import { request } from "../utils/axios-utils";
import { useEffect, useState } from "react";
import { getDateRange } from "../utils/helpers";

const useAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>(null);
  const [analytics, setAnalytics] = useState({
    compound_return: "0",
    largest_drawdown: "0",
    monthly_return: "0",
    monthly_sharpe_ratio: "",
    winning_bets_ratio: "0",
    yearly_return: "0",
  });

  const getAnalyticsData = async (range?: any) => {
    try {
      const [start, end] = getDateRange(range)
      setLoading(true);
      const { data } = await request({
        url: `/analytics?start=${start}&end=${end}`,
        method: "GET",
      });
      setAnalytics(data["analytics"]);
      let objectInitXAxis: any = [];
      let objectInitYAxis: any = [];
      data["analytics"]["cumulative_pnls"].forEach((EachObj: any) => {
        for (const [key, value] of Object.entries(EachObj)) {
          objectInitXAxis.push(key);
          objectInitYAxis.push(Number(value) * 100);
        }
      });
      setChartData({ YAxis: objectInitYAxis, XAxis: objectInitXAxis });
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnalyticsData();
  }, []);

  return { getAnalyticsData, loading, setLoading, analytics, chartData };
};
export default useAnalytics;
