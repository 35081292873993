import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios-utils";
const initialState = {
  loading: false,
  depositeLoading: false,
  deposite: [],
  withDrawLoading: false,
  isWithDrawSuccess: "",
  message: "",
  type: "",
};

export const depositSlice = createSlice({
  name: "deposits",
  initialState,
  reducers: {
    clearMessage: (state: any) => {
      state.message = "";
      state.type = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addDeposit.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addDeposit.fulfilled, (state, action) => {
      if (action.payload.response?.status === 400) {
        state.message = action.payload.response.data.error;
        state.type = "error";
      } else {
        state.isWithDrawSuccess = "success";
        state.type = "success"
      }
      state.loading = false;
    });
    builder.addCase(addDeposit.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(widthDraw.pending, (state, action) => {
      state.loading = true;
      state.isWithDrawSuccess = "";
    });
    builder.addCase(widthDraw.fulfilled, (state, action) => {
      if (action.payload.response?.status === 400) {
        state.message = action.payload.response.data.error;
        state.type = "error";
      } else {
        state.isWithDrawSuccess = "success";
        state.type = "success"
      }
      state.loading = false;
    });
    builder.addCase(widthDraw.rejected, (state, action) => {
      state.loading = false;
      state.isWithDrawSuccess = "failed";
      state.message = "";
    });
  },
});

export const addDeposit = createAsyncThunk(
  "deposits/addDeposit",
  async (payload: any) => {
    const data = await request({
      url: `/deposits`,
      method: "POST",
      data: payload,
    });

    return data;
  }
);

export const widthDraw = createAsyncThunk(
  "deposits/withDraw",
  async (payload: any, thunkAPI) => {
    try {
      const data = await request({
        url: `/withdrawals`,
        method: "POST",
        data: payload,
      });

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const { clearMessage } = depositSlice.actions;
export default depositSlice.reducer;
