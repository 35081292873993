import ReactDOM from "react-dom/client";
import "./index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { Login } from "./pages/auth/Login";
import { Deposit } from "./pages/deposit/Deposit";
import { Bet } from "./pages/bet/Bet";
import { Withdraw } from "./pages/withdraw/Withdraw";
import { Settings } from "./pages/settings/Settings";
import { Helpdesk } from "./pages/helpdesk/Helpdesk";
import { History } from "./pages/history/History";
import { Analytics } from "./pages/analytics/Analytics";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store/store";
// 1615663126-la4qosnrjjn1f34h9q518vqdidcj3a7f.apps.googleusercontent.com
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/deposit",
    element: <Deposit />,
  },
  {
    path: "/deposit/new",
    element: <Deposit />,
  },
  {
    path: "/withdraw",
    element: <Withdraw />,
  },

  {
    path: "/bet",
    element: <Bet />,
  },

  {
    path: "/settings",
    element: <Settings />,
  },

  {
    path: "/helpdesk",
    element: <Helpdesk />,
  },

  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },
]);


ReactDOM.createRoot(document.getElementById("root") as HTMLDivElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);
