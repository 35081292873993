import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios-utils";
const initialState = {
  loading: false,
  fireblocks_token: ""
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(setSettings.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(setSettings.fulfilled, (state, action) => {
      if (action.payload.response?.status === 200) {
        state.fireblocks_token = action.payload.response.users[0].fireblocks_token;
      }
      state.loading = false;
    })
    builder.addCase(setSettings.rejected, (state, action) => {
      state.loading = false;
    })
  },
});

export const setSettings = createAsyncThunk(
  "settings/set",
  async (payload: any, thunkAPI) => {
    try {
      const data = await request({
        url: `/users`,
        method: "PATCH",
        data: payload,
      });

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export default settingsSlice.reducer;
