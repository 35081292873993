import { set, format } from "date-fns";

const renderMoney: any = (money: string, currency: string) => {
  const decimalPlaces = currency === "BTC" || currency === "ETH" ? 8 : 2;
  return (Math.floor(Number(money) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces);
};


function getDatesForDateRange(range: any) {
  let startDate: Date;
  let endDate: Date;

  if (range && range.startDate && range.endDate) {
    startDate = new Date(range.startDate);
    endDate = new Date(range.endDate);
  } else {
    startDate = new Date(2024, 1, 1);
    endDate = new Date();
  }

  startDate = set(startDate, { hours: 0, minutes: 0, seconds: 0 });
  endDate = set(endDate, { hours: 23, minutes: 59, seconds: 59 });
  return [startDate, endDate]
}

function getDateRange(range: any) {
  const [startDate, endDate] = getDatesForDateRange(range)

  const formattedStartDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ss");
  const formattedEndDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

  return [formattedStartDate, formattedEndDate];
}

export { renderMoney, getDateRange, getDatesForDateRange };
