import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios-utils";
const initialState = {
  coins: [],
  loading: false,
};

export const coinsSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getCoins.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCoins.fulfilled, (state, action) => {
      state.coins = action.payload;
      state.loading = false;
    });
    builder.addCase(getCoins.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const getCoins = createAsyncThunk("coins/getAllCoins", async () => {
  const { data } = await request({
    url: `/brands`,
  });

  return data;
});

export default coinsSlice.reducer;
