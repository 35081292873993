import { HelpdeskOver } from "../../components/HelpdeskOver";
import { Topics } from "../../utils/constants";
import Layout from "../../components/Layout";

export const Helpdesk = () => {
  return (
    <div className="w-full">
      <Layout active={7} page="Help Desk">
        <div className="md:w-[90%]  w-[692px] mx-auto mt-[36px]">
          <h1 className="text-[32px] text-center font-semibold text-white mb-8">
            Help Desk
          </h1>

          <ul className="flex flex-col gap-4">
            {Topics.map((EachFaq) => (
              <HelpdeskOver EachFaq={EachFaq} />
            ))}
          </ul>
        </div>
      </Layout>
    </div>
  );
};
