"use client";
import { ToggleSwitch } from "flowbite-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import useSettings, { getFirebaseToken } from "../hooks/useSettings";
import { setSettings } from "../store/slices/settings";
import { useDispatch } from "react-redux";

export const SettingsCard = () => {
  const {settings, notificationsOn, setNotifications, loading} = useSettings();
  
  const dispatch = useDispatch<any>();
  
  const handleToggleSwitchChange = async (checked: boolean) => {
    try {
      let token = ""
      if (checked) {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          throw new Error("Notification permission denied");
        }
        token = await getFirebaseToken()
      }
      dispatch(setSettings({firebase_token: token}))
      setNotifications(checked)
    } catch (error) {
      console.log("Error requesting notification permission:", error);
    }
  };

  

  return (
    <div>
      {loading && <Loader />}
      <ToastContainer hideProgressBar={true} />
      <div className="md:w-[90%] mx-auto  w-[575px] p-[30px] bg-darkBlue rounded-[24px] border-[1px] border-darkBlueGray sm:p-[16px] mb-5">
        <div className="flex items-center justify-center">
          <img
            src={settings.profile_picture_url}
            alt=""
            className="rounded-lg"
          />
        </div>
        <div className="grid grid-cols-2 mt-6 gap-5 ">
          <div>
            <label htmlFor="#" className="text-white text-[17px] mb-2 block">
              First Name
            </label>
            <div className="flex w-full rounded-[12px] border-[1px] border-darkBlueGray h-[50px] items-center px-3">
              <input
                type="text"
                readOnly
                value={settings.first_name}
                className="bg-[transparent] text-lightGray2 text-[16px] flex-1 w-full outline-none border-0 placeholder:text-lightGray2"
              />
            </div>
          </div>
          <div>
            <label htmlFor="#" className="text-white text-[17px] mb-2 block">
              Last Name
            </label>
            <div className="flex w-full rounded-[12px] border-[1px] border-darkBlueGray h-[50px] items-center px-3">
              <input
                type="text"
                readOnly
                value={settings.last_name}
                className="bg-[transparent] text-lightGray2 text-[16px] flex-1 w-full outline-none border-0 placeholder:text-lightGray2"
              />
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="flex bg-darkBluish w-full rounded-[12px] border-[1px] border-darkBlueGray1 h-[50px] items-center px-3 cursor-pointer justify-between">
            <p className="text-white font-medium text-[16px]">
              Activate Notifications
            </p>
            <ToggleSwitch checked={notificationsOn} onChange={handleToggleSwitchChange}/>
          </div>
        </div>

      </div>
    </div>
  );
};
