import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import date from "../assets/img/date.svg";
import {
  startOfMonth,
  format,
  startOfYear,
  subDays,
} from "date-fns";

import { DefinedRange, DateRange } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface IModalProps {
  cb: any;
  current?: string;
}

const CustomStaticRangeLabelContent = ({ text, date }: any) => {
  return (
    <div className={`text-lightGray py-1  font-medium text-md`}>{text}</div>
  );
};

const renderStaticRangeLabel = (props: any) => {
  return (
    <>
      <CustomStaticRangeLabelContent date={props.range} text={props.label} />
    </>
  );
};

const DateModal = ({ cb, current }: IModalProps) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(current);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const staticLabels = [
    {
      label: "This Month",
      hasCustomRendering: true,
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
        label: "This Month",
      }),
      isSelected() {
        return true;
      },
    },
    {
      label: "This Year",
      hasCustomRendering: true,
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: new Date(),
        label: "This Year",
      }),
      isSelected() {
        return true;
      },
    },
    {
      label: "Last 30 days",
      hasCustomRendering: true,
      range: () => ({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        label: "Last 30 days",
      }),
      isSelected() {
        return true;
      },
    },
    {
      label: "Last 365 days",
      hasCustomRendering: true,
      range: () => ({
        startDate: subDays(new Date(), 365),
        endDate: new Date(),
        label: "Last 365 days",
      }),
      isSelected() {
        return true;
      },
    },
    {
      label: "All Time",
      hasCustomRendering: true,
      range: () => ({
        startDate: new Date(2024, 1, 1),
        endDate: new Date(),
        label: "All Time",
      }),
      isSelected() {
        return true;
      },
    },
  ];

  const [state, setState] = useState<any>([
    {
      startDate: staticLabels
        ?.find((item: any) => item.label === current)
        ?.range().startDate,
      endDate: null,
      key: "selection",
      label: "",
    },
  ]);

  const handleSubmit = useCallback(
    (date: any) => {
      cb && cb(state);
      handleClose();
    },
    [cb, state]
  );

  const showFormattedDate = (data: any, isRange: boolean) => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);

    if (isRange) {
      const start = format(startDate, "dd MMM");
      const end = format(endDate, "dd MMM");
      return `${start} - ${end}`;
    } else {
      return data.label;
    }
  };
  return (
    <React.Fragment>
      <div
        className={`flex items-center bg-darkBluish justify-between gap-2 px-4  py-[10px] h-full border-[1px] border-selectBorder w-[174px] sm:w-full rounded-2xl cursor-pointer`}
        onClick={handleClickOpen}
      >
        <img src={date} alt="" />
        <p className="text-white font-semibold sm:text-sm text-[15px]  capitalize">
          {currentValue}
        </p>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: "999",
        }}
        PaperProps={{
          sx: {
            background: "#171B35",
            color: "white",
            maxWidth: "700px",
            width: "100%",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Date Range</DialogTitle>
        <DialogContent className="bg-none">
          <DialogContentText id="alert-dialog-description">
            <div className="flex w-full flex-row sm:flex-col gap-2">
              <div className="customCalender flex-1 sm:flex-[0.3] rounded-xl">
                <DefinedRange
                  onChange={(item: any) => {
                    setState([item.selection]);
                    setCurrentValue(
                      showFormattedDate(item?.selection || "", false)
                    );
                  }}
                  ranges={state}
                  inputRanges={[]}
                  renderStaticRangeLabel={renderStaticRangeLabel}
                  staticRanges={staticLabels}
                />
              </div>
              <div className="customCalender flex-1 sm:flex-[0.7]">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    setState([item.selection]);
                    setCurrentValue(
                      showFormattedDate(item?.selection || "", true)
                    );
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={{
              bgcolor: "#23284f",
              padding: "10px 20px",
              fontSize: "14px",
              textTransform: "capitalize",
              "@media (max-width: 639px)": {
                fontSize: "12px",
                padding: "5px 10px",
              },
              color: "white",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disableRipple
            sx={{
              bgcolor: "#23284f",
              padding: "10px 20px",
              fontSize: "14px",
              "@media (max-width: 639px)": {
                fontSize: "12px",

                padding: "5px 10px",
              },
              textTransform: "capitalize",
              color: "white",
            }}
            onClick={handleSubmit}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DateModal;
