import dropdown from "../../assets/img/dropdown.svg"
import date from "../../assets/img/date.svg"
import tick from "../../assets/img/tick.svg"
import { useCallback, useEffect, useState } from "react"
import { MenuItem, Pagination } from "@mui/material"
import Layout from "../../components/Layout"
import MUISelect from "../../components/MUISelect"
import { useDispatch, useSelector } from "react-redux"
import DateModal from "../../components/DateModal"
import { setBalance, setCurrency } from "../../store/slices/general"
import useHistory from "../../hooks/useHistory"
import { renderMoney } from "../../utils/helpers"

export const History = () => {
  const dispatch = useDispatch<any>()

  const {
    pageCount,
    currentItem,
    setCurrentItem,
    loading,
    totalHistory,
    itemOffset,
    setItemOffset,
    allRecords,
    setRange,
    setpageCount,
    setTotalHistory,
  } = useHistory()

  const [active, setActive] = useState(false)
  const [typeMbl, settypeMbl] = useState("")

  const [historyUtilArea] = useState<any>([])

  const { global } = useSelector((state: any) => state)
  const { currency } = global
  const filterRecords = useCallback((typeMbl: any) => {
    const endOffset = itemOffset + 10
    let filtered = allRecords
    if (typeMbl) {
      const filters = typeMbl
        .split(",")
        .map((filter: any) => filter.trim().toLowerCase())
      filtered = allRecords?.filter((item: any) =>
        filters.includes(item.type)
      )
    }
    setTotalHistory(filtered.length)
    if (window.innerWidth < 500) {
      setCurrentItem(filtered)
    } else {
      setCurrentItem(filtered.slice(itemOffset, endOffset))
    }
    setpageCount(Math.ceil(filtered.length / 10))
  }, [allRecords, itemOffset, setCurrentItem, setTotalHistory, setpageCount])


  const handlePageClick = useCallback(
    (value: any) => {
      const newOffset = ((value - 1) * 10) % totalHistory
      setItemOffset(newOffset)
      const endOffset = newOffset + 10

      if (typeMbl) {
        filterRecords(typeMbl)
      } else {
        setTotalHistory(allRecords.length)
        setCurrentItem(allRecords.slice(newOffset, endOffset))
      }
    },
    [allRecords, totalHistory, typeMbl,setCurrentItem, setItemOffset, filterRecords, setTotalHistory]
  )

  const typeFilters = ["Deposit", "Withdrawal", "Withdrawal Fee", "Withdrawal Cancelled", "Bet Placed", "Bet Win", "Bet Void"]

  useEffect(() => {
    filterRecords(typeMbl)
  }, [filterRecords, typeMbl])
  const getData = (params: any) => {
    const dates = params[0]
    setRange(dates)
  }

  const renderCoins = useCallback(() => {
    return global?.accounts?.map((item: any, index: number) => {
      return (
        <MenuItem
          className=" border-b-darkBlueGray  text-lightGray2 h-[56px]"
          value={item.symbol}
          sx={{
            fontSize: "16px",
            paddingX: "0px",
            borderBottom:
              global?.accounts.length - 1 === index ? "" : "1px solid",
            borderColor: "#444869",
          }}
        >
          <div className="px-2">{item.symbol}</div>
        </MenuItem>
      )
    })
  }, [global])

  return (
    <div className="w-full">
      <Layout loading={loading} active={3} page="Account History">
        <div className="1lg:w-[90%]  w-[1140px] mx-auto  mt-[36px] mb-[60px]">
          <div className="flex   my-4  justify-end">
            <div className="flex sm:flex-1  gap-10 items-end">
              <MUISelect
                className={"w-[50%]"}
                onChange={(e: any) => {
                  dispatch(setCurrency(e.target.value))
                  dispatch(setBalance(e.target.value))
                }}
                value={currency}
              >
                {renderCoins()}
              </MUISelect>
              <div className="h-full">
                <DateModal current="All Time" cb={getData} />
              </div>
            </div>
          </div>
          <div className="p-[30px] rounded-[24px] bg-darkBlue border-[1px] border-darkBlueGray sm:p-4 relative">
            <ul className="flex flex-col gap-4">
              <div
                className={`1lg:px-8 sm:!px-4 w-[358px] absolute top-[70px]  left-[220px] 1lg:w-[100%] 1lg:left-1/2 1lg:-translate-x-1/2 1lg:top-[90px] sm:!top-[76px] hidden ${
                  active && "!block"
                }`}
              >
                <ul className="px-4 w-full rounded-bl-[16px] rounded-br-[16px] bg-darkBlue filter-wrapper  border-[1px] border-blue">
                  {typeFilters?.map((filter) => {
                    return (
                      <li
                        className="flex items-center gap-2 px-4 h-[56px] border-b-[1px] border-b-darkBlueGray"
                      >
                        <input
                          type="checkbox"
                          id={filter}
                          className="checkbox-type hidden"
                          value={filter}
                        />
                        <label
                          htmlFor={filter}
                          className="w-[22px] h-[22px] border-[1px] border-[#CCCCCC] rounded flex items-center justify-center"
                        >
                          <img src={tick} alt="" className="hidden" />
                        </label>
                        <p className="text-lightGray2 text-[16px] font-bold">
                          {filter}
                        </p>
                      </li>
                    )
                  })}

                  <li className="flex items-center gap-2 py-3 border-b-[1px] border-b-darkBlueGray">
                    <button
                      className="text-white tect-[16px] w-full h-[49px] bg-blue  rounded-[12px]"
                      onClick={(e) => {
                        let parent = document.querySelector(".filter-wrapper")
                        let checkedInputs =
                          parent?.querySelectorAll("li input:checked")

                        let checkboxvalues = [] as any
                        let historyLocal = [] as any
                        let checkboxInputValue = ""
                        checkedInputs?.forEach((EachInput, key) => {
                          let input = EachInput as HTMLInputElement
                          checkboxvalues.push(input.value)
                          checkboxInputValue += `${key > 0 ? "," : ""} ${
                            input.value
                          }`
                        })

                        historyUtilArea.forEach((Each: any) => {
                          let type = Each.type.toLowerCase()

                          checkboxvalues.forEach((EachValue: any) => {
                            let valueLower = EachValue.toLowerCase()

                            if (valueLower === type) {
                              historyLocal.push(Each)
                            }
                          })
                        })

                        settypeMbl((prev) => checkboxInputValue)
                        setActive(false)
                      }}
                    >
                      Save Filter
                    </button>
                  </li>
                </ul>
              </div>

              <li className="px-5 rounded-[16px]  flex items-center 1lg:flex-col 1lg:items-start 1lg:gap-2 1lg:px-0">
                <div className="w-[255px] flex items-center gap-3 1lg:hidden">
                  <p className="text-lightGray text-[16px] font-medium">Date</p>
                </div>
                <div
                  className={`w-[211px] cursor-pointer  flex items-center gap-[10px] 1lg:bg-darkBluish 1lg:py-4 1lg:px-5 1lg:w-full 1lg:rounded-[16px] 1lg:border-[1px] 1lg:border-darkBlueGray 1lg:justify-between ${
                    active && "!rounded-bl-none !rounded-br-none"
                  }`}
                  onClick={(e) => {
                    setActive(!active)
                  }}
                >
                  <p className="text-lightGray text-[16px] font-medium sm:hidden">
                    Type
                  </p>
                  <p className="text-lightGray text-[16px] font-medium hidden sm:block">
                    {typeMbl === "" ? "Type" : typeMbl}
                  </p>
                  <img src={dropdown} alt="" className="cursor-pointer" />
                </div>
                <div className="flex-1 flex items-center 1lg:hidden ">
                  <p className="text-lightGray text-[16px] font-medium">
                    Description
                  </p>
                </div>
                <div className="w-[172px] 1lg:hidden 1lg:justify-start flex items-center  gap-2">
                  <p className="text-lightGray text-[16px] pl-5 font-medium">
                    Amount
                  </p>
                </div>
                <div className="w-[104px] 1lg:hidden 1lg:justify-start flex items-center justify-start ">
                  <p className="text-lightGray text-[16px] font-medium">
                    Final Balance
                  </p>
                </div>
              </li>

              {currentItem.map((Each: any, key: any) => (
                <li
                  key={key}
                  className=" bg-darkBluish py-4 px-5 rounded-[16px]  border-[1px] border-darkBlueGray flex items-center 1lg:grid 1lg:grid-cols-2 1lg:items-start 1lg:gap-2"
                >
                  <div className="w-[255px] flex items-center gap-3 1lg:w-full pr-3">
                    <img src={date} alt="" />
                    <p className="text-lightGray2 text-[16px] font-medium">
                      {Each.created_at}
                    </p>
                  </div>
                  <div className="w-[211px] 1lg:w-[101px] 1lg:ml-auto">
                    <button
                      className="flex items-center justify-center font-bold border-[1px] 1lg:w-full px-[20px] py-[9px]  rounded-[10px]  sm:px-[0px] capitalize bg-[#1e233f] border-[#9CA0AF] text-[#9CA0AF]">
                      {Each.type}
                    </button>
                  </div>
                  <div className="flex-1 flex items-center 1lg:col-span-2 pr-3">
                    <p
                      className="text-lightGray2 text-[16px] font-medium"
                      style={{ wordBreak: "break-all" }}
                    >
                      {Each.description}
                    </p>
                  </div>
                  <div className="w-[172px] pl-5 1lg:w-full 1lg:justify-start flex items-center  gap-2 sm:pl-0">
                    <p className="text-[#9CA0AF] text-[16px] font-medium">
                      {Each.change.split("-")?.length === 1 && "+"}

                      {renderMoney(Each["change"], currency)}
                    </p>
                  </div>
                  <div className="w-[104px] 1lg:w-full 1lg:justify-start flex items-center justify-start ">
                    <p className="text-lightGray2 text-[16px] font-medium">
                      {renderMoney(Each["balance"], currency)}
                    </p>
                  </div>
                </li>
              ))}

              {allRecords?.length === 0 && (
                <li className=" bg-darkBluish py-4 px-5 rounded-[16px]  border-[1px] border-darkBlueGray h-[200px] flex items-center justify-center">
                  <div className="w-full text-center">
                    <p className="text-lightGray2 text-[20px] font-bold">
                      No History Found
                    </p>
                  </div>
                </li>
              )}
            </ul>

            {window.innerWidth > 500 && (
              <div className="flex items-center mt-5 justify-center gap-[5px] sm:gap-[2px]">
                <Pagination
                  count={pageCount}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    value: number
                  ) => {
                    handlePageClick(value)
                  }}
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  )
}
