import dropdownBet from "../../assets/img/dropdown-bet.svg";
import dropdownBetWhite from "../../assets/img/plus-white.svg";

import { useState, useEffect } from "react";

import Pagination from "@mui/material/Pagination";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAccounts,
  setBalance,
  setCurrency,
} from "../../store/slices/general";
import useBets from "../../hooks/useBets";
import { renderMoney } from "../../utils/helpers";

export const Bet = () => {
  const {
    pageCount,
    currentItem,
    setCurrentItem,
    loading,
    totalBets,
    setItemOffset,
    allRecords,
    getBets,
  } = useBets({ getAll: true });

  const [active, setActive] = useState(false);
  const { global } = useSelector((state: any) => state);
  const { accounts, balance, currency } = global;

  const handlePageClick = (value: any) => {
    const newOffset = ((value - 1) * 10) % totalBets;

    setItemOffset(newOffset);

    const endOffset = newOffset + 10;
    setCurrentItem(allRecords.slice(newOffset, endOffset));
  };

  const dispatch = useDispatch<any>();
  
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getUserAccounts())
      getBets(false); 
    }, 5000);

    return () => clearInterval(interval); 
  }, [dispatch, getBets]);

  return (
    <div className="w-full">
      <Layout active={1} page="Bet History" loading={loading}>
        <>
          <div className="1lg:w-[90%] sm:!w-full sm:pl-4   w-[1140px] mx-auto mt-[36px] ">
            <div className="grid grid-cols-3 gap-3 mb-8 sm:flex sm:overflow-scroll">
              <div
                className={`px-[20px] sm:px-[15px]   py-[12px] sm:min-w-[251px] rounded-[16px] bg-blue relative sm:static ${
                  active && "rounded-bl-none rounded-br-none"
                }`}
              >
                <h1 className="text-[16px] text-lightGray lg:text-[13px] font-bold">
                  Balance
                </h1>
                <div className="flex items-center justify-between mt-2">
                  <p className="text-[30px] text-lightGray2 font-bold lg:text-[20px]">
                    <span className=" inline-block min-w-[80px] sm:w-[unset] sm:mr-1">
                      {renderMoney(balance["balance"], currency)}
                    </span>{" "}
                    {currency}
                  </p>
                  {active ? (
                    <img
                      src={dropdownBetWhite}
                      alt=""
                      className="cursor-pointer"
                      onClick={(e) => {
                        setActive(!active);
                      }}
                    />
                  ) : (
                    <img
                      src={dropdownBet}
                      alt=""
                      className="cursor-pointer"
                      onClick={(e) => {
                        setActive(!active);
                      }}
                    />
                  )}
                </div>
                {active && (
                  <ul className="bg-darkBlue px-[20px] border-[1px] border-blue rounded-bl-[8px] left-0 top-[100%] rounded-br-[8px] absolute w-full z-20 sm:top-[185px] sm:w-[251px] sm:left-[1rem]">
                    {accounts.map((EachList: any) => (
                      <li
                        onClick={(e) => {
                          setActive(false);
                          dispatch(setCurrency(EachList?.symbol));
                          dispatch(setBalance(EachList?.symbol));
                        }}
                        className="text-[16px] font-bold text-lightGray2 h-[56px]  justify-between border-b-[1px] border-b-darkBlueGray flex items-center cursor-pointer sm:w-[unset]"
                      >
                        <span className="inline-block w-[100px] sm:w-[unset] mr-3 text-left sm:text-left ">
                          {renderMoney(EachList["balance"], EachList.symbol)}
                        </span>
                        <span className=" inline-block w-[80px] ">
                          {EachList.symbol}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="px-[20px] sm:min-w-[154px] py-[12px] rounded-[16px] bg-darkBlue border-[1px] border-darkBlueGray  sm:px-[15px]">
                <h1 className="text-[16px] text-lightGray  lg:text-[13px]  font-bold">
                  P&L
                </h1>
                <div className="flex items-center justify-between mt-2">
                  <p
                    className={`text-[30px] font-bold lg:text-[20px]
               ${
                 balance?.pnl?.split("-").length > 1
                   ? "text-[#EB5757] "
                   : "text-[#27AE60] "
               }`}
                  >
                    {renderMoney(balance["pnl"], currency)}
                  </p>
                </div>
              </div>
              <div className="px-[20px] py-[12px] rounded-[16px] bg-darkBlue  border-[1px] border-darkBlueGray  sm:min-w-[154px] sm:px-[15px] sm:mr-3">
                <h1 className="text-[16px] text-lightGray lg:text-[13px]  font-bold">
                  Open bets
                </h1>
                <div className="flex items-center justify-between mt-2">
                  <p className="text-[30px] text-lightGray2 font-bold lg:text-[20px]">
                    {renderMoney(balance["unsettled_balance"], currency)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="1lg:w-[90%]  w-[1140px] mx-auto  mb-[60px]">
            <div className="p-[30px] rounded-[24px] bg-darkBlue border-[1px] border-darkBlueGray sm:p-4">
              <ul className="flex flex-col gap-4">
                {currentItem.map((EachBet: any) => {
                  let sportName = EachBet.sport.replace(/\s/g, "");
                  if (sportName === "Formula") sportName += "1";
                  const images = require(`../../assets/img/sports/${sportName}.jpeg`);

                  return (
                    <li
                      key={EachBet._id}
                      className=" bg-darkBluish py-4 px-5 rounded-[16px]  border-[1px] border-darkBlueGray flex items-center 1lg:flex-col 1lg:items-start 1lg:gap-2"
                    >
                      <div className="w-[330px] pr-[30px] sm:pr-[0px] flex items-center gap-3 1lg:w-full">
                        <img
                          src={images}
                          className="w-[45px] rounded-full"
                          alt=""
                        />

                        <p className="text-lightGray2 font-bold sm:text-[15px]">
                          {EachBet["event"]}
                        </p>
                      </div>
                      <div className="w-[198px] 1lg:w-full">
                        <p className="text-lightGray2 text-[14px] sm:text-[14px]">
                          {EachBet["bet"]}
                        </p>
                      </div>
                      <div className="flex-1  flex items-center justify-center">
                        <p className="text-lightGray2 text-[14px]   font-medium">
                          {EachBet["created_at"]}
                        </p>
                      </div>
                      <div className="flex-1 flex items-center justify-center">
                        <p className="text-lightGray2 text-[14px] font-medium">
                          <span>Stake:</span>
                          {renderMoney(EachBet["staked"], currency)}{' '}
                          <br className="sm:hidden 1lg:hidden"/>
                          ({(Number(EachBet["stake"]) * 100).toFixed(2)}%)
                        </p>
                      </div>
                      <div
                        className={`w-[230px] 1lg:w-full 1lg:justify-start flex items-center  gap-2 ${
                          Math.abs(
                            EachBet["staked"] * (1 - EachBet["odd"])
                          ).toFixed(3) === "0.000" && "justify-end"
                        }`}
                      >
                        {EachBet["status"] !== "open" ? (
                          <>
                            {Math.abs(
                              EachBet["staked"] * (1 - EachBet["odd"])
                            ) !== 0 && (
                              <span
                                className={`flex items-center ${
                                  EachBet["status"] === "win"
                                    ? "text-green"
                                    : "text-red"
                                }  font-semibold  w-[100px] pr-[10px] sm:text-[12px] flex-1  sm:flex-[unset] sm:w-[100px] sm:text-center sm:justify-center rounded-[10px] text-[18px]`}
                              >
                                {EachBet["status"] === "lose"
                                  ? renderMoney(EachBet["staked"], currency)
                                  : renderMoney(
                                      Math.abs(
                                        EachBet["staked"] * (1 - EachBet["odd"])
                                      ),
                                      currency
                                    )}
                              </span>
                            )}
                          </>
                        ) : (
                          <span className="flex items-center  font-semibold  w-[100px] pr-[10px] sm:text-[12px] flex-1  sm:flex-[unset] sm:w-[100px] sm:text-center sm:justify-center rounded-[10px] text-[18px] opacity-0"></span>
                        )}
                        <span className="flex w-[90px] text-[14px] items-center justify-center text-white font-bold  border-[1px] border-blue 1lg:w-full px-[10px] py-[9px] rounded-[10px] sm:!w-[100px]  sm:px-[10px] sm:text-[12px]">
                          Odd: {Number(EachBet["odd"]).toFixed(3)}
                        </span>
                        <span
                          className={`flex ${
                            EachBet["status"] === "win" &&
                            "text-[#25AF60] border-[#25AF60] bg-[#25af614e]"
                          } ${
                            EachBet["status"] === "lose" &&
                            "text-red border-red bg-[#2D2139]"
                          }
                          ${
                            EachBet["status"] !== "win" &&
                            EachBet["status"] !== "lose" &&
                            "text-blue border-blue bg-[#1D285D]"
                          }
                          
                          items-center text-[14px] justify-center  font-bold border-[1px] 1lg:w-full  px-[20px] py-[9px] rounded-[10px] sm:px-[10px] sm:text-[12px] w-[60px]  capitalize sm:!w-[100px]`}
                        >
                          {EachBet["status"] === "win" && "Won"}
                          {EachBet["status"] === "lose" && "Lost"}
                          {EachBet["status"] === "open" && "Open"}
                          {EachBet["status"] === "live" && "Live"}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {window.innerWidth > 500 && (
                <div className="flex items-center mt-5 justify-center gap-[5px] sm:gap-[2px]">
                  <Pagination
                    count={pageCount}
                    onChange={(
                      event: React.ChangeEvent<unknown>,
                      value: number
                    ) => {
                      handlePageClick(value);
                    }}
                    color="primary"
                  />
                </div>
              )}
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
};