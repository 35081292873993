import Select from "@mui/material/Select";
import dropdown from "../assets/img/dropdown.svg";

interface ISelectProps {
  children: React.ReactNode;
  value: any;
  [key: string]: any;
  className?: any;
}

const CustomIcon = () => {
  return (
    <img
      src={dropdown}
      alt=""
      className="mx-4 absolute w-[30px] right-0 h-[20px] -z-10"
    />
  );
};
const MUISelect = ({ children, value, className, ...rest }: ISelectProps) => {
  return (
    <Select
      {...rest}
      displayEmpty
      value={value || ""}
      className={` bg-darkBluish h-[50px]  ${className} `}
      sx={{
        backgroundColor: "#171B35",
        fontSize: "16px",
        position: "relative",
        zIndex: "1",
        "@media (max-width: 639px)": {
          fontSize: "0.875rem",
        },

        borderRadius: "12px",
        color: "white",
        "& .MuiSelect-icon": {
          color: "white",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#38425c",
        },

        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#38425c",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "#23284f",
          },
        },

        MenuListProps: {
          sx: {
            backgroundColor: "#23284f",
            paddingX: "8px",
          },
          className: "text-lightGray2",
        },
      }}
      IconComponent={CustomIcon}
    >
      {children}
    </Select>
  );
};

export default MUISelect;
