import { request } from "../utils/axios-utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDateRange } from "../utils/helpers";

const useAccounts = () => {
  const navigate = useNavigate();

  const { global } = useSelector((state: any) => state);
  const { currency } = global;

  const [loading, setLoading] = useState(false);
  const [pLData, setPLData] = useState<any>(null);

  const getPandLData = async (range?: any) => {
    try {
      const [start, end] = getDateRange(range)
      setLoading(true);
      const { data } = await request({
        url: `/accounts?symbol=${currency}&start=${start}&end=${end}`,
        method: "GET",
      });
      setPLData(data?.accounts);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (token: string) => {
    setLoading(true);
    try {
      const { data } = await request({
        url: `/sessions`,
        method: "post",
        data: {
          token,
        },
      });

      window.localStorage.setItem("token", data["token"]);
      window.localStorage.setItem("currency", "BTC");
      setLoading(false);
      navigate("/bet");
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return {
    getPandLData,
    pLData,
    loading,
    setLoading,
    loginUser,
  };
};
export default useAccounts;
