import { request } from "../utils/axios-utils";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDateRange } from "../utils/helpers";

const useHistory = () => {
  const { global } = useSelector((state: any) => state);
  const { currency } = global;

  const [totalHistory, setTotalHistory] = useState<number>(0);
  const [currentItem, setCurrentItem] = useState<any>([]);

  const [allRecords, setAllRecords] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [range, setRange] = useState<any>([]);
  const getHistory = useCallback(async () => {
    try {
      const [start, end] = getDateRange(range)
      setLoading(true);
      let url = `/ledger?symbol=${currency}&start=${start}&end=${end}`;
      const { data } = await request({
        url: url,
        method: "GET",
      });

      setpageCount(Math.ceil(data["ledger"].length / 10));
      setAllRecords(data["ledger"]);
      setTotalHistory(data["ledger"].length);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [currency, range]);

  useEffect(() => {
    getHistory();
  }, [getHistory, currency, range]);
  return {
    getHistory,
    loading,
    itemOffset,
    setItemOffset,
    currentItem,
    setCurrentItem,
    pageCount,
    setTotalHistory,
    totalHistory,
    setLoading,
    setAllRecords,
    allRecords,
    range,
    setRange,
    setpageCount,
  };
};
export default useHistory;
