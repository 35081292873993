import { request } from "../utils/axios-utils";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";

class GoogleJWTToken {
  picture!: string;
  given_name!: string;
  family_name!: string;
}

const firebaseConfig = {
  apiKey: "AIzaSyDBqtla2aEJzAU-IsPRMlNY7v0ysHWiLpw",
  authDomain: "freedom-x-413118.firebaseapp.com",
  projectId: "freedom-x-413118",
  storageBucket: "freedom-x-413118.appspot.com",
  messagingSenderId: "1615663126",
  appId: "1:1615663126:web:4b09e8c50fda05212a6537",
  measurementId: "G-LXK4805LHK",
};

const useSettings = () => {
  const [notificationsOn, setNotifications] = useState(false);
  const [loading, setLoading] = useState(false);
  initializeApp(firebaseConfig);

  const getSettings = useCallback(async () => {
    try {
      const token = window.localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }
      const decoded = jwtDecode<GoogleJWTToken>(token);
      const settings = {
        first_name: decoded.given_name,
        last_name: decoded.family_name,
        profile_picture_url: decoded.picture,
      };
      setLoading(true);
      const { data } = await request({
        url: `/users`,
        method: "GET",
      });
      if (Notification.permission === "granted") {
        const firebase_token = await getFirebaseToken();
        setNotifications(
          Boolean(firebase_token) && firebase_token === data.user.firebase_token
        );
      }
      return settings;
    } catch (err) {
      console.log("error getting settings:", err);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const [settings, setSettings] = useState({
    first_name: "",
    last_name: "",
    profile_picture_url: "",
  });

  useEffect(() => {
    getSettings().then((settings: any) => {
      if (settings) {
        setSettings(settings);
      }
    });
  }, [getSettings]);

  return { loading, notificationsOn, setNotifications, settings };
};

export default useSettings;

export async function getFirebaseToken() {
  const vapidKey =
    "BPAm-oWjD88xo9AzecNWxy3FuBweAGbzKQWYQAsgRdGjkOYI_YiI8cwkcjLXQsyeaU9TdNZ5ryW6ddzi0y5cst0";
  return await getToken(getMessaging(), { vapidKey });
}
