import { request } from "../utils/axios-utils";
import _ from 'lodash';

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDateRange } from "../utils/helpers";

const useBets = ({ getAll }: any) => {
  const { global } = useSelector((state: any) => state);
  const { currency } = global;

  const [totalBets, setTotalBets] = useState<number>(0);
  const [totalStakes, setTotalStakes] = useState<any>("");
  const [currentItem, setCurrentItem] = useState<any>([]);

  const [allRecords, setAllRecords] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [range, setRange] = useState<any>([]);

  const getBets = useCallback(async (showLoader: boolean = true) => {
    const betsChanged = async () => {
      const { top5 } = await request({
        url: `/bets?symbol=${currency}&limit=5`,
        method: "GET",
      })
      return (_.isEqual(top5["bets"], allRecords.slice(0, 5)))
    };

    try {
      const [start, end] = getDateRange(range)
      setLoading(true && showLoader);
      let url = `/bets?symbol=${currency}&start=${start}&end=${end}`;
      if (getAll) url = `/bets?symbol=${currency}`;
      if (getAll && !showLoader && !await betsChanged()) {
        return
      }
      const { data } = await request({
        url: url,
        method: "GET",
      });

      setpageCount(Math.ceil(data["bets"].length / 10));
      const endOffset = itemOffset + 10;

      if (window.innerWidth < 500) {
        setCurrentItem(data["bets"]);
      } else {
        setCurrentItem(data["bets"].slice(itemOffset, endOffset));
      }
      setAllRecords(data["bets"]);
      setTotalBets(data["bets"].length);

      const isStakeGreaterThanZero = (item: any) => parseFloat(item.staked) > 0;
      const isSettled = (item: any) => item.status !== "open"

      const countStakesGreaterThanZero = (dataArray: any) => {
        const filteredData = dataArray.filter(isStakeGreaterThanZero).filter(isSettled);
        return filteredData.length;
      };

      setTotalStakes(countStakesGreaterThanZero(data["bets"]));
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [currency, getAll, range, itemOffset]);

  useEffect(() => {
    getBets();
  }, [getBets, currency, range]);
  return {
    getBets,
    loading,
    itemOffset,
    setItemOffset,
    currentItem,
    setCurrentItem,
    pageCount,
    totalBets,
    setLoading,
    setAllRecords,
    totalStakes,
    allRecords,
    range,
    setRange,
  };
};
export default useBets;
