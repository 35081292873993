import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios-utils";

const initialState = {
  networks: [],
  loading: false,
};

export const networkSlice = createSlice({
  name: "networks",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getnetworks.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getnetworks.fulfilled, (state, action) => {
      state.networks = action.payload;
      state.loading = false;
    });
    builder.addCase(getnetworks.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const getnetworks = createAsyncThunk(
  "networks/getAllnetworks",
  async () => {
    const { data } = await request({
      url: `/networks`,
    });

    return data.networks;
  }
);

export default networkSlice.reducer;
