import { useEffect } from "react";
import { ConfimPayment } from "../../components/ConfimPayment";
import { ErrorPayment } from "../../components/ErrorPayment";
import Layout from "../../components/Layout";
import WithdrawCard from "../../components/WithdrawCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../store/slices/deposit";

export const Withdraw = () => {
  const dispatch = useDispatch();
  const deposit = useSelector((state: any) => state.deposit);

  useEffect(() => {
    if (deposit.type) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 5000);
    }
  }, [dispatch, deposit?.type]);

  return (
    <div className="w-full">
      <Layout active={5} page="Withdraw">
        <div className="md:w-[100%] w-[534px] mx-auto mt-[36px]">
          <WithdrawCard />
          {deposit?.type === "success" && (
            <ConfimPayment header="Withdrawal Requested" />
          )}
          {deposit?.type === "error" && (
            <ErrorPayment message={deposit.message} />
          )}
        </div>
      </Layout>
    </div>
  );
};
