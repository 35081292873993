import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import copys from "../assets/img/copy.svg";
import copyQr from "../assets/img/copy-qr.svg";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import MUISelect from "./MUISelect";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { useForm, Controller } from "react-hook-form";
import copy from "copy-to-clipboard";
import { jwtDecode } from "jwt-decode";
import { addDeposit } from "../store/slices/deposit";
import { getnetworks } from "../store/slices/networks";
import { getUserAccounts } from "../store/slices/general";
import { setBalance, setCurrency } from "../store/slices/general";
import Loader from "./Loader";
import { Tooltip as ReactTooltip } from "react-tooltip";

const DepositCard = () => {
  const dispatch = useDispatch<any>();

  const isDisabled = true

  useEffect(() => {
    dispatch(getnetworks());
    dispatch(getUserAccounts());
  }, [dispatch]);

  const { control, handleSubmit, watch, setValue } = useForm();
  const [, setpasteValue] = useState("");
  const onSubmit = (data: any) => {
    let payload = {
      network: data?.netWorkValue,
      tx_hash: data?.transactionProof,
      symbol: currency,
    };

    dispatch(addDeposit(payload));
  };

  const {
    networks: networkData,
    global,
    deposit,
  } = useSelector((state: any) => state);

  const { networks } = networkData;
  const { accounts, currency } = global;
  const { loading } = deposit;

  const transferEmail = watch("transferEmail");
  const transactionProof = watch("transactionProof");

  const address = watch("address");
  const renderNetworks = useCallback(
    (currency: string) => {
      return (
        networks?.length > 0 &&
        networks
          ?.filter((item: any) =>
            item?.symbols.some((symbol: any) => symbol.id === currency)
          )
          .map((item: any) => {
            return (
              <MenuItem
                key={item["_id"]}
                className="border-b-darkBlueGray  text-lightGray2 h-[56px]"
                value={item["_id"]}
                sx={{
                  fontSize: "16px",
                  paddingX: "0px",
                  borderColor: "#444869",
                }}
              >
                <div className="px-2">{item["_id"]}</div>
              </MenuItem>
            );
          })
      );
    },
    [networks]
  );

  const renderCoins = useCallback(() => {
    return accounts?.map((item: any, index: number) => {
      return (
        <MenuItem
          className=" border-b-darkBlueGray  text-lightGray2 h-[56px]"
          value={item.symbol}
          sx={{
            fontSize: "16px",
            paddingX: "0px",
            borderBottom: accounts.length - 1 === index ? "" : "1px solid",
            borderColor: "#444869",
          }}
        >
          <div className="px-2">{item.symbol}</div>
        </MenuItem>
      );
    });
  }, [accounts]);

  useEffect(() => {
    if (networks?.length > 0) {
      const selectedItem = networks?.find((item: any) =>
        item?.symbols.some((symbol: any) => symbol.id === currency)
      );
      if (selectedItem) {
        setValue("netWorkValue", selectedItem["_id"]);
        setValue("address", selectedItem["our_addresses"][0]);
      }
    }
  }, [networks, currency, setValue]);

  useEffect(() => {
    if (accounts?.length > 0) setValue("currency", accounts[0]?.symbol);
  }, [accounts, setValue]);

  useEffect(() => {
    const token = window.localStorage.getItem("token") || "{}";
    const decoded = jwtDecode(token) as any;
    if (currency === "EUR") {
      const email = decoded["email"];
      const atIndex = email.indexOf("@");
      const cleanEmail = email
        .substring(0, atIndex)
        .replace(/[.!#$%&'*+/=?^_`{|}~-]/g, "");
      setValue("transferEmail", cleanEmail);
    }
  }, [accounts, currency, setValue]);
  return (
    <div>
      {loading && <Loader />}
      <ToastContainer autoClose={5000} hideProgressBar={true} />

      <div className="md:w-[90%] mx-auto  w-[534px] p-[30px] bg-darkBlue rounded-[24px] border-[1px] border-darkBlueGray sm:p-[16px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <label
              htmlFor="currency"
              className="text-white text-[17px] mb-2 block"
            >
              Select Coin
            </label>
            <FormControl fullWidth>
              <Controller
                name="currency"
                control={control}
                render={() => (
                  <MUISelect
                    value={currency}
                    onChange={(e: any) => {
                      dispatch(setCurrency(e.target.value));
                      dispatch(setBalance(e.target.value));
                    }}
                  >
                    {renderCoins()}
                  </MUISelect>
                )}
              />
            </FormControl>
          </div>

          <div className="mt-6">
            <label
              htmlFor="netWorkValue"
              className="text-white text-[17px] mb-2 block"
            >
              Select network
            </label>
            <FormControl fullWidth>
              <Controller
                name="netWorkValue"
                control={control}
                render={({ field }) => (
                  <MUISelect {...field}>{renderNetworks(currency)}</MUISelect>
                )}
              />
            </FormControl>
          </div>

          <div className="mt-6 flex items-center  gap-[13px]">
            {currency !== "EUR" && (
              <div className="p-[6px] bg-white w-[94px] h-[94px] rounded-lg">
                <QRCode className="w-full h-full" value={address || "2545"} />
              </div>
            )}
            <div className="flex-1">
              <h1 className="text-white font-bold">
                {currency !== "EUR" ? "Deposit Address" : "IBAN"}
              </h1>
              <div className="flex items-center justify-between mt-[14px]">
                <p className="w-[194px] break-words text-white text-[14px] sm:w-[150px]">
                  {address}
                </p>
                <img
                  src={copyQr}
                  alt=""
                  className="cursor-pointer"
                  onClick={(e) => {
                    copy(address);
                  }}
                />
              </div>
            </div>
          </div>

          {currency !== "EUR" && (
            <div className="my-6">
              <label
                htmlFor="#"
                className="text-white text-[17px]   mb-2 block"
              >
                Transaction Proof (Transaction Hash)
              </label>

              <div className="flex bg-darkBluish w-full rounded-[12px]  border-[1px] border-darkBlueGray1 h-[50px] items-center px-3 cursor-pointer">
                <input
                  type="text"
                  className="bg-[transparent] text-lightGray placeholder:sm:text-[12px] sm:text-[12px] text-[16px] flex-1 w-full outline-none border-0"
                  value={transactionProof}
                  onChange={(e) => {
                    setValue("transactionProof", e.target.value);
                  }}
                />

                <img
                  src={copys}
                  alt=""
                  className="cursor-pointer"
                  onClick={async (e) => {
                    const text = await navigator.clipboard.readText();
                    setValue("transactionProof", text);
                  }}
                />
              </div>
            </div>
          )}

          {currency === "EUR" && (
            <div className="my-6">
              <label htmlFor="#" className="text-white text-[17px] mb-2 block">
                Transfer Reference
              </label>

              <div className="flex w-full  h-[50px] items-center  cursor-pointer">
                <input
                  type="text"
                  className="bg-[transparent] text-lightGray text-[14px] flex-1 w-full outline-none border-0"
                  readOnly
                  value={transferEmail}
                  onChange={(e) => {
                    setValue("transferEmail", e.target.value);
                    copy(e.target.value);
                  }}
                />

                <img
                  src={copyQr}
                  alt=""
                  className="cursor-pointer"
                  onClick={async (e) => {
                    const text = await navigator.clipboard.readText();
                    setpasteValue(text);
                    copy(text);
                  }}
                />
              </div>

              <p className="text-lightGray text-[14px] mt-2">
                Please add the above as the reference of the transfer, so that
                the deposit can be identified.
              </p>
              {isDisabled && (
                <p className="text-red text-[14px] mt-2">
                  Deposits are unavailable for the time being. Withdrawals are
                  unaffected.
                </p>
              )}
            </div>
          )}
          {isDisabled && (
            <ReactTooltip
              className="break-words  max-w-52 z-30 text-sm font-normal !bg-[#3958FF]"
              id={"buttonNote"}
              place="bottom"
              content={
                "Deposits are unavailable for the time being. Withdrawals are unaffected."
              }
            />
          )}
          {currency !== "EUR" && (
            <button
              disabled={isDisabled}
              type="submit"
              data-tooltip-id={"buttonNote"}
              className={` tect-[16px] w-full h-[49px] ${
                isDisabled ? "bg-lightGray text-red" : "bg-blue text-white"
              }  rounded-[12px]`}
            >
              Deposit
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default DepositCard;
