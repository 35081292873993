import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import burger from "../assets/img/burger.svg";
import burgeractive from "../assets/img/burger-active.svg";

const NavItem = ({ to, active, children }: any) => (
  <Link
    to={to}
    className={`text-white font-semibold text-[16px] md:h-[58px] md:border-b-[1px] md:border-b-darkGray1  h-[44px] px-4 justify-center flex items-center ${
      active &&
      "bg-darkBlue1 md:rounded-lg  rounded-full border-[1px] border-orange md:border-b-orange"
    }`}
  >
    {children}
  </Link>
);

export const Header = ({ active, page }: any) => {
  const [navActive, setNavActive] = useState(false);

  const toggleNavActive = () => setNavActive(!navActive);

  const renderNavItems = (isMobil: boolean) => (
    <>
      <NavItem to="/Bet" active={active === 1}>
        Bet History
      </NavItem>
      <NavItem to="/analytics" active={active === 2}>
        Analytics
      </NavItem>
      <NavItem to="/history" active={active === 3}>
        Account History
      </NavItem>
      <NavItem to="/deposit" active={active === 4}>
        Deposit
      </NavItem>
      <NavItem to="/withdraw" active={active === 5}>
        Withdraw
      </NavItem>
      <NavItem to="/settings" active={active === 6}>
        Account Settings
      </NavItem>
      <NavItem to="/helpdesk" active={active === 7}>
        Help Desk
      </NavItem>
      {!isMobil && (
        <Link
          to="/"
          onClick={() => window.localStorage.removeItem("token")}
          className="text-white font-semibold text-[16px] h-[58px] px-4 justify-center flex items-center "
        >
          Logout
        </Link>
      )}
    </>
  );

  return (
    <header className="w-full sticky top-0 z-[888] bg-darkBluish h-[70px] border-b-[1px] border-b-darkBlueGray1">
      {navActive && (
        <ul className="bg-darkBluish px-[20px] border-[1px] border-blue rounded-bl-[8px] right-[3px] hidden 1lg:block py-[16px] top-[70px] max-w-[315px] w-full rounded-br-[8px] absolute z-50 ">
          {renderNavItems(false)}
        </ul>
      )}
      <div className="max-w-[1140px] mx-auto h-full w-[90%] flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Link to="/">
            <img
              src={logo}
              alt=""
              className="sm:w-[45px] cursor-pointer sm:h-[45px] h-[50px] w-[50px]"
            />
          </Link>
        </div>
        <h1 className="text-white font-semibold hidden 1lg:block lg:absolute lg:left-1/2 lg:-translate-x-1/2">
          {page}
        </h1>
        <div className="hidden 1lg:block">
          <img
            src={navActive ? burgeractive : burger}
            alt=""
            onClick={toggleNavActive}
          />
        </div>
        <div className="flex items-center gap-3 1lg:hidden">
          <nav className="flex items-center h-[52px] border-[1px] border-darkBlueGray1 rounded-full px-1">
            {renderNavItems(true)}
          </nav>
          <nav className="flex items-center h-[52px] border-[1px] border-darkBlueGray1 rounded-full px-1">
            <Link
              onClick={() => window.localStorage.removeItem("token")}
              to="/"
              className="text-white font-semibold text-[16px] h-[44px] px-4 justify-center flex items-center "
            >
              Logout
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};
