import axios from "axios";

const client = axios.create({
  baseURL: `https://freedom-x-413118.ew.r.appspot.com`,
});

export const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "token"
  )}`;

  const onSuccess = (response: any) => {
    return response;
  };

  const onError = (error: any) => {
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};
