import info from "../assets/img/info.svg";
import lineimg from "../assets/img/line.svg";
import pieImg from "../assets/img/pie.svg";
import handImg from "../assets/img/hand.svg";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import CustomToolTip from "./Tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const PerformanceBox = ({
  heading,
  number,
  hoverData,
  chartType,
  bar = null,
  chartsData = null,
}: any) => {
  let options: ApexOptions = {};
  if (chartsData != null) {
    options = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        },
      },
      colors: ["#3958FF"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: chartsData["XAxis"],
        labels: {
          style: {
            colors: "#CCCCCC",
            fontSize: "14px",
            cssClass: "Manrope",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
          style: {
            colors: "#CCCCCC",
            fontSize: "14px",
            cssClass: "Manrope",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(1) + "% Compound Profit";
          },
        },
      },
    };
  }

  return (
    <div
      className={`px-5 relative py-3 rounded-[12px]  bg-darkBlue border-[1px] border-darkBlueGray ${
        bar && "!pb-0"
      }`}
    >
      <div className="flex justify-between">
        <div>
          <h1 className="text-lightGray  relative inline-flex items-center gap-2 text-[16px] font-bold">
            {heading}
            <ReactTooltip
              className="break-words  max-w-52 z-30 text-sm font-normal !bg-[#3958FF]"
              id={heading}
              place="bottom"
              content={hoverData}
            />
            {/* <CustomToolTip
              placement="top"
              title={
                <h1
                  style={{
                    fontSize: "14px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  {hoverData}
                </h1>
              }
            > */}
            <img
              src={info}
              data-tooltip-id={heading}
              alt=""
              className="info-area cursor-pointer"
            />
            {/* </CustomToolTip> */}
          </h1>

          <p className="text-lightGray2 font-bold text-[25px] mt-2 md:text-[20px]">
            {number}
          </p>
        </div>
        {chartType === "line" && (
          <img src={lineimg} alt="" className="w-[130px]" />
        )}
        {chartType === "pie" && <img src={pieImg} alt="" />}
        {chartType === "bets" && <img src={handImg} alt="" />}
      </div>
      {chartType === "bar" && (
        <>
          {chartsData["YAxis"].length > 0 ? (
            <ReactApexChart
              options={options}
              series={[{ name: "Compound Profit", data: chartsData["YAxis"] }]}
              type="bar"
              height={268}
            />
          ) : (
            <p className="text-lightGray2 font-bold text-[25px] mt-2 md:text-[20px]">
              No data available
            </p>
          )}
        </>
      )}
    </div>
  );
};
